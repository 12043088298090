import Vue from 'vue';
import { ClsSectionProps } from './ClsSection.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'ClsSection',
	props: ClsSectionProps,
	data() {
		return {
			allDone: true,
			preparationFinished: true,
			shouldDisplay: this.readyToDisplay,
			dimensions: {
				width: 0,
				height: 0,
			},
			sizeWatcher: 0,
			shiftingStopped: true,
			clsTimeout: 0,
		};
	},
	watch: {
		readyToDisplay(newValue: boolean) {
			this.shouldDisplay = newValue;
			this.checkForDone();
		},
		active() {
			if (!this.active) {
				this.preparationFinished = true;
				this.shouldDisplay = true;
				this.checkForDone();
				this.cleanup();
			}
		},
	},
	created() {
		// when in client and optimization is active
		if (process.client && this.active) {
			this.allDone = false;
			this.preparationFinished = false;
		}
	},
	mounted() {
		if (!this.slotContainsClsValidElements() || !this.active) {
			this.finishClsPreparation();
		} else if (!this.preparationFinished) {
			this.clsTimeout = this.timeout > 0 ? Date.now() + this.timeout : -1;
			this.checkUpdateDimensions();
		}
	},
	beforeDestroy() {
		this.cleanup();
	},
	methods: {
		finishClsPreparation() {
			this.clearWatcher();
			this.preparationFinished = true;
			this.checkForDone();
			this.$emit('prepared', this.uid);
		},
		slotContainsClsValidElements() {
			return !!this.$children.length && this.$children.some((elm) => elm.$el.nodeType !== 8);
		},
		updateDimensions() {
			if (this.$el && (this.$el.clientWidth > 0 || this.$el.clientHeight > 0)) {
				this.dimensions.height = this.$el.clientHeight;
				this.dimensions.width = this.$el.clientWidth;
			}
		},
		checkUpdateDimensions() {
			const isTimedOut = this.clsTimeout > 0 && Date.now() > this.clsTimeout;
			this.shiftingStopped = this.dimensionsRegistered() && !this.dimensionsChanged();

			if (process.server || isTimedOut || this.shiftingStopped) {
				this.finishClsPreparation();
			} else {
				this.sizeWatcher = window.requestAnimationFrame(() => {
					this.updateDimensions();
					// check if now size change during the last 100ms
					this.checkUpdateDimensions();
				});
			}
		},
		checkForDone() {
			if (this.preparationFinished && this.shouldDisplay && !this.allDone) {
				this.allDone = true;
				this.$emit('done', this.uid);
			}
		},
		dimensionsChanged() {
			return (
				this.$el &&
				this.dimensions.height !== this.$el.clientHeight &&
				this.dimensions.width !== this.$el.clientWidth
			);
		},
		dimensionsRegistered() {
			return this.dimensions.width > 0 || this.dimensions.height > 0;
		},
		clearWatcher() {
			if (process.client) {
				window.cancelAnimationFrame(this.sizeWatcher);
			}
		},
		cleanup() {
			this.clearWatcher();
		},
	},
});
