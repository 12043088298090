import { equals } from '@/node_modules/@osp/design-system/assets/js/utilities/compare';
import { setSafeTimeout } from '@/node_modules/@osp/design-system/assets/js/utilities/timeout';
import { IClsBaseMixin } from '@/node_modules/@osp/design-system/components/mixins/cls-base-mixin';
import {
	LcpOptimizationMixin,
	ILcpOptimizationMixin,
} from '@/node_modules/@osp/design-system/components/mixins/lcp-optimization-mixin';
import { IClsOptimizationMixin } from '@/node_modules/@osp/design-system/components/mixins/cls-optimization-mixin';
import { ContentPageMixin, contentSlots } from './content-page-mixin';
import { Component, Mixins, Watch } from '@/app-utils/decorators';
import { useClsStore } from '~/@api/store/clsApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useUxStore } from '~/@api/store/uxApi';
import { ClsOptimizationMixin } from '~/components/mixins/cls-optimization-mixin';
import BasePage from '~/pages/base-page/base-page';
import { TrackingMixin } from '~/pages/tracking.mixin';
import ContentSlot from '~/components/molecules/content-slot';

@Component({
	extends: BasePage,
	components: { ContentSlot },
	mixins: [LcpOptimizationMixin, ClsOptimizationMixin, TrackingMixin, ContentPageMixin],
})
export default class ContentPage extends Mixins<
	ContentPageMixin & IClsBaseMixin & IClsOptimizationMixin & ILcpOptimizationMixin
>(ContentPageMixin, LcpOptimizationMixin) {
	public clsPreparedComponents = {
		Breadcrumb: false,
		ContentNavigation: false,
		AllContentSlots: false,
		...contentSlots.reduce((pv, _cv, index) => ({ ...pv, [`ContentSlot_${index}`]: false }), {}),
	};

	public clsManualComponentKey = 'ContentPage';
	private renderKey = Date.now();
	private contentUpdateOngoing = useUxStore(this.$store).state.pageSwitchInProgress;

	get showContentNavigation(): boolean {
		return !this.BREAKPOINT.IS_MOBILE && this.navigation && !!this.navigation.length;
	}

	get is404Page(): boolean {
		return useRoutingStore(this.$store).state.spaData.datalayer.page.pageInfo.pageID === 'notFound';
	}

	get pageSwitchInProgress() {
		return useUxStore(this.$store).state.pageSwitchInProgress;
	}

	public clsUpdateUID(): void {
		this.clsUID = this.pageIdentifier;
	}

	@Watch('contentSlots', { deep: true })
	contentSlotsWatcher(newContentSlots, oldContentSlots) {
		if (equals(newContentSlots, oldContentSlots)) return;

		this.contentUpdateOngoing = true;

		const { api: uxApi } = useUxStore(this.$store);

		uxApi.setFooterVisible(false);
		uxApi.setPageSwitchProgressStatus(true);
	}

	@Watch('pageSwitchInProgress')
	pageSwitchWatcher(switchInProgress) {
		if (!switchInProgress) {
			setSafeTimeout(() => {
				this.contentUpdateOngoing = false;

				useClsStore(this.$store).api.activateClsStateAll();
			}, this.clsData.config.fallbackTimerMs);
		}
	}

	created() {
		(this as ILcpOptimizationMixin).lcp.optimizationDesktop = true;
	}

	protected onContentSlotPrepared(index: number): void {
		this.clsRegisterPrepared(`ContentSlot_${index}`);

		if (this.contentSlots.length - 1 === index) {
			this.clsPreparedComponents.Breadcrumb = true;
			this.clsPreparedComponents.ContentNavigation = true;
			this.clsPreparedComponents.AllContentSlots = true;
		}
	}
}
