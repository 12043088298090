import { stringify } from './stringify';

export function equals(itemA: any, itemB: any) {
	if (typeof itemA !== typeof itemB || !!itemA !== !!itemB) {
		return false;
	}

	if (typeof itemA === 'object' && typeof itemB === 'object') {
		return objectsAreEqual(itemA, itemB);
	}

	return itemA === itemB;
}

function objectsAreEqual(objA: { [k: string]: any }, objB: { [k: string]: any }): boolean {
	const obj1Keys = Object.keys(objA);
	const obj2Keys = Object.keys(objB);

	if (obj1Keys.length !== obj2Keys.length) {
		return false;
	} else {
		obj1Keys.sort((a, b) => (a > b ? -1 : 1));
		obj2Keys.sort((a, b) => (a > b ? -1 : 1));

		return obj1Keys.every((key, index) => {
			const objValue1 =
				typeof objA[key] === 'function' || typeof objA[key] === 'object'
					? stringify(objA[key])
					: objA[key];
			const objValue2 =
				typeof objB[obj2Keys[index]] === 'function' || typeof objB[obj2Keys[index]] === 'object'
					? stringify(objB[obj2Keys[index]])
					: objB[obj2Keys[index]];
			return objValue1 === objValue2;
		});
	}
}
