import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface ClsSectionModel {
	uid: string;
	active: boolean;
	timeout: number;
	readyToDisplay: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const ClsSectionProps: Props<ClsSectionModel> = {
	uid: {
		type: String as PropType<ClsSectionModel['uid']>,
		required: true,
	},
	timeout: {
		type: Number as PropType<ClsSectionModel['timeout']>,
		default: 1500,
	},
	active: {
		type: Boolean as PropType<ClsSectionModel['active']>,
		default: true,
	},
	readyToDisplay: {
		type: Boolean as PropType<ClsSectionModel['readyToDisplay']>,
		default: true,
	},
};
