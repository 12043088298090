import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Link } from '../../types/link';

// Model -------------------------------------------------------------------------------------------

export interface BreadcrumbModel {
	uid: string;
	links: Link[];
	current: string;
	isStatic?: boolean;
	isBigVariant?: boolean;
	showHome?: boolean;
	showCurrent?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const BreadcrumbProps: Props<BreadcrumbModel> = {
	uid: {
		type: String as PropType<BreadcrumbModel['uid']>,
		required: true,
	},
	links: {
		type: Array as PropType<BreadcrumbModel['links']>,
		required: true,
	},
	current: {
		type: String as PropType<BreadcrumbModel['current']>,
		required: true,
	},
	isStatic: {
		type: Boolean as PropType<BreadcrumbModel['isStatic']>,
	},
	isBigVariant: {
		type: Boolean as PropType<BreadcrumbModel['isBigVariant']>,
		default: false,
	},
	showHome: {
		type: Boolean as PropType<BreadcrumbModel['showHome']>,
		default: false,
	},
	showCurrent: {
		type: Boolean as PropType<BreadcrumbModel['showCurrent']>,
		default: false,
	},
};
