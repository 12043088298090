import Vue from 'vue';
import { Link } from '../../types/link';
import { ClsComponentMixin } from '../mixins/cls-component-mixin';
import { BreadcrumbProps } from './Breadcrumb.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'Breadcrumb',
	mixins: [ClsComponentMixin],
	props: BreadcrumbProps,
	computed: {
		home(): Link {
			return this.links[0];
		},
		items(): Link[] {
			return this.links.length > 1 ? this.links.slice(1, -1) : [];
		},
		parent(): Link | null {
			return this.links.length > 1 ? this.links[this.links.length - 1] : null;
		},
	},
});
