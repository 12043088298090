import Logger from '@/node_modules/@osp/utils/src/logger';
import { Link } from '@/node_modules/@osp/design-system/types/link';
import { stringify } from '@/node_modules/@osp/design-system/assets/js/utilities/stringify';
import { NavigationEntry } from '@/node_modules/@osp/design-system/types/navigation';
import { Mixin, Prop, Vue } from '@/app-utils/decorators';
import { getJson } from '~/app-utils/http';
import { backend, url } from '~/@api/backend';
import { useCmsContentStore } from '~/@api/store/cmsContentApi';
import { useLoadingStore } from '~/@api/store/loadingApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { useUserStore } from '~/@api/store/userApi';
import { ContentNavigationNode } from '~/generated/hybris-raml-api';

export const contentSlots = [
	'TopContent',
	'Section1',
	'Section2',
	'Section3',
	'Section4',
	'Section5',
	'CenterContentSlot',
	'BodyContent',
	'Stage',
	'TopSeller',
	'OSPContent',
];

@Mixin
export class ContentPageMixin extends Vue {
	@Prop()
	private labelOrId: string;

	@Prop({ default: '' })
	private fileExtension: string;

	private componentPreloadCount = 3;
	private entries: ContentNavigationNode[] = [];

	get pageIdentifier() {
		const currentGender = useUserStore(this.$store).state.user?.gender?.code;

		if (this.labelOrId === 'homepage') {
			return currentGender === 'NEUTRAL' ? 'homepage' : `gender_${currentGender}`;
		}

		return this.labelOrId;
	}

	get isStructuredContent() {
		return this.pageIdentifier?.startsWith('cp/');
	}

	get storageKey() {
		return `${
			useServerContextStore(this.$store).state.session.language
		}_ochsnersport-content-navigation`;
	}

	get breadcrumbData() {
		const home: Link = {
			href: url(this.$store),
			text: this.$t(this.$i18nKeys.breadcrumb.home),
		};
		const breadcrumb = [];
		const breadCrumbElementBuilder = (entry) => ({
			href: entry.href,
			text: entry.label,
		});
		const breadcrumbBuilder = (entry) => {
			// If entry or child seletected: add to the breadcrumb
			if (entry.selected || entry.children.some(breadcrumbBuilder)) {
				return breadcrumb.unshift(breadCrumbElementBuilder(entry));
			}

			// Otherwise return false to go to sibling
			return false;
		};

		this.navigation.some(breadcrumbBuilder);

		return {
			links: [home, ...breadcrumb.slice(0, -1)],
			current: breadcrumb[breadcrumb.length - 1]?.text || '',
			showCurrent: true,
			showHome: true,
			uid: 'BREADCRUMB',
		};
	}

	get navigation(): NavigationEntry[] {
		const entryMapper = (entry: ContentNavigationNode) => ({
			...entry,
			children: entry.children?.map(entryMapper) || [],
			selected: entry?.href?.endsWith(`/${this.pageIdentifier}${this.fileExtension}`),
			seoHref: entry?.href,
		});

		return this.entries?.map(entryMapper);
	}

	get contentSlots() {
		let currentComponentPreloadCount = this.componentPreloadCount;

		return contentSlots.map((contentSlotId) => {
			const contentSlot = {
				id: contentSlotId,
				componentPreloadCount: currentComponentPreloadCount,
			};

			currentComponentPreloadCount -= useCmsContentStore(this.$store).api.slot(
				contentSlotId,
			).length;

			return contentSlot;
		});
	}

	private async fetchNavigation() {
		try {
			const response = (await getJson(backend.API.V2.CONTENT_NAVIGATION(this.$store), this.$store))
				?.json;

			return response?.navigationNodes as ContentNavigationNode[];
		} catch (error) {
			Logger.error('Could not fetch content navigation', error);
		}
		return [];
	}

	async updateSideNavigation() {
		if (this.isStructuredContent) {
			const cachedEntries = process.client
				? (window as any).osp.sessionStorage.getItem(this.storageKey)
				: '[]';

			if (cachedEntries && cachedEntries !== '[]') {
				this.entries = JSON.parse(cachedEntries);
			} else {
				await useLoadingStore(this.$store).api.doWithLoader('load-content-structure', async () => {
					this.entries = await this.fetchNavigation();

					this.storeEntries();
				});
			}
		}
	}

	// Fetch is only executed on SSR page load or when navigation from another
	// page type to a content page -> routeEnter. On routeUpdate, only the prop
	// labelOrId is changed but the ContentPage component is reused
	async fetch() {
		await this.updateSideNavigation();
	}

	updated() {
		if (!this.entries?.length) {
			this.updateSideNavigation();
		} else {
			this.storeEntries();
		}
	}

	private storeEntries() {
		if (process.client) {
			(window as any).osp.sessionStorage.setItem(this.storageKey, stringify(this.entries));
		}
	}
}
