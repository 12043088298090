import { Route } from 'vue-router';
import { isFirefox } from '@/node_modules/@osp/design-system/assets/js/utilities/browserDetectors';
import { useUxStore } from '~/@api/store/uxApi';
import { TrackingMixin } from '~/pages/tracking.mixin';
import { clientInitServerSettings } from '~/@api/store/serverSettingsApi';
import { clientInitI18nStore } from '~/@api/store/i18nApi';
import { clientInitCmsContentStore } from '~/@api/store/cmsContentApi';

export default {
	mixins: [TrackingMixin],
	beforeCreate() {
		if (process.client) {
			clientInitServerSettings(this.$store);
			clientInitI18nStore(this.$store);
			clientInitCmsContentStore(this.$store);
		}
	},
	beforeRouteLeave(_to: Route, _from: Route, next) {
		if (process.client) {
			const { api: uxApi } = useUxStore(this.$store);

			// Avoid shifting of footer when page content disappears on route change
			uxApi.setFooterVisible(false);
			uxApi.setPageSwitchProgressStatus(true);

			// If in Firefox, cancel all running cancelable requests. Otherwise, Firefox will log errors for open fetches
			if (isFirefox()) {
				(window as any).osp?.http?.cancel('About to leave route');
			}
		}

		next();
	},
};
