import Vue from 'vue';
import { IClsConfig } from '@/node_modules/@osp/design-system/components/mixins/cls-base-mixin';
import { LcpOptimizationMixin } from '@/node_modules/@osp/design-system/components/mixins/lcp-optimization-mixin';
import { useClsStore } from '~/@api/store/clsApi';

export default Vue.extend({
	name: 'ClsSectionWrapper',
	components: {
		ClsSection: () =>
			import('@/node_modules/@osp/design-system/components/ClsSection/ClsSection.vue'),
	},
	mixins: [LcpOptimizationMixin],
	props: {
		uid: { type: String, required: true },
		clsKey: { type: String, required: true },
		readyToDisplay: { type: Boolean, default: true },
		active: { type: Boolean, default: true },
	},
	data() {
		return {
			clsConfig: undefined as IClsConfig,
		};
	},
	created() {
		this.clsConfig = useClsStore(this.$store).state.config;
	},
	methods: {
		forwardDone(e) {
			this.$emit('done', this.clsKey || e);
			// compatibility for old emits
			this.$emit('clsPreparationFinished', this.clsKey || e);
		},
	},
});
